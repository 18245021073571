import React, { FC } from 'react'

import useRetailPrices from '../../../../../hooks/useRetailPrices'
import { Product } from '../../../../../lib/products'
import { ProductDetails } from '../../../../../lib/products/modal/types'
import { openQuickViewProductModal } from '../../../../../lib/products/quick-view-modal'
import useSanityImage from '../../../../../lib/sanity/hooks/useSanityImage'
import { formatAsCurrency } from '../../../../../utils/priceTools'
import Link from '../../../../global/Link'
import {
  BannerAddToCartButton,
  LinkAndCtaContainer,
  ProductBannerCardContainer,
  ProductMinimumPriceAmount,
  ProductMinimumPriceContainer,
  ProductMinimumPriceText,
  ProductPrice,
  ProductRetailPriceAmount,
  ProductRetailPriceContainer,
  ProductRetailPriceText,
  ScrollableContainer,
  Separator,
} from './styled'

import productImagePlaceholder from '../../../../../img/product-image-placeholder.png'

export interface ProductBannerCardProps {
  className?: string
  product: Product
  productDetails?: ProductDetails
  analyticsEventAppContext?:
    | 'plp'
    | 'related_products'
    | 'featured_products'
    | 'dispensary_featured_products'
}

const ProductBannerCard: FC<ProductBannerCardProps> = ({
  className,
  product,
  productDetails,
  analyticsEventAppContext,
}) => {
  const { shouldDisplayRetailPrice } = useRetailPrices()
  const sanityImageUrl = useSanityImage(
    { sourceJson: product.sanityImageJson || undefined },
    {
      dpr: 2,
      fit: 'max',
      // set width only to avoid crop bug: https://github.com/sanity-io/image-url/issues/32
      width: 175,
    },
  )
  const productUrl = sanityImageUrl || productImagePlaceholder

  return (
    <ProductBannerCardContainer className={className}>
      <div className="product-image">
        <Link to={`/products/${product.urlKey}/`}>
          <img alt={product.name || undefined} src={productUrl} />
        </Link>
      </div>
      <Separator />
      <div className="product-details">
        <Link to={`/products/${product.urlKey}/`}>
          <h4>{product.name}</h4>
        </Link>
        <LinkAndCtaContainer>
          {product.priceRange?.minimum && (
            <ScrollableContainer>
              <ProductPrice>
                <ProductMinimumPriceContainer>
                  <ProductMinimumPriceText>
                    {product.priceRange?.minimum !== product.priceRange?.maximum ? `from ` : null}{' '}
                  </ProductMinimumPriceText>
                  <ProductMinimumPriceAmount>
                    {formatAsCurrency({ amount: parseFloat(product.priceRange?.minimum) })}
                  </ProductMinimumPriceAmount>
                </ProductMinimumPriceContainer>
                {product.retailPriceRange?.minimum && shouldDisplayRetailPrice && (
                  <ProductRetailPriceContainer>
                    <ProductRetailPriceText>retail</ProductRetailPriceText>
                    <ProductRetailPriceAmount>
                      {formatAsCurrency({
                        amount: parseFloat(product.retailPriceRange?.minimum),
                      })}
                    </ProductRetailPriceAmount>
                  </ProductRetailPriceContainer>
                )}
              </ProductPrice>
            </ScrollableContainer>
          )}
          <BannerAddToCartButton
            onClick={() =>
              openQuickViewProductModal({
                sku: product.sku,
                urlKey: product.urlKey,
                productDetails,
                analyticsEventAppContext,
                controls: 'add_to_cart',
              })
            }
          >
            Add to Cart
          </BannerAddToCartButton>
        </LinkAndCtaContainer>
      </div>
    </ProductBannerCardContainer>
  )
}

export default ProductBannerCard
