import React, { FC } from 'react'
import styled from 'styled-components'

import type { Breadcrumb } from '../lib/products'
import Link from './global/Link'

export interface BreadcrumbsProps {
  className?: string
  breadcrumbs: Breadcrumb[]
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs, className }) => (
  <div className={className}>
    {breadcrumbs.map((breadcrumb) => {
      if (breadcrumb.path) {
        return (
          <Link className="breadcrumb robotoCondensed" key={breadcrumb.label} to={breadcrumb.path}>
            {breadcrumb.label}
          </Link>
        )
      }
      return (
        <span key={breadcrumb.label} className="breadcrumb">
          {breadcrumb.label}
        </span>
      )
    })}
  </div>
)

Breadcrumbs.defaultProps = {
  className: '',
}

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  fontSize: '10px',
  letterSpacing: '0.06em',
  lineHeight: '12px',
  textTransform: 'uppercase',
  padding: '15px 15px 0',
  '.breadcrumb:not(:last-child)': {
    '&:after': {
      content: '">"',
      // Prevent the link underline from applying to this character as well:
      display: 'inline-block',
      marginLeft: '8px',
      marginRight: '8px',
      textDecoration: 'none',
    },
  },
  a: {
    color: theme.colors.coolGray.cool600,
    textDecoration: 'underline',
  },
  '@media(min-width: 600px)': {
    fontSize: '12px',
    lineHeight: '14px',
    maxWidth: 'none',
  },
  '@media(min-width: 992px)': {
    padding: '30px 80px 0 40px',
  },
}))

export default StyledBreadcrumbs
