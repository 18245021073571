import React, { FC } from 'react'
import styled from 'styled-components'

import { SanityParentProductFragment } from '../../../../graphql/gatsby'
import {
  ConfigurableProductDetailsFragment,
  SimpleProductDetailsFragment,
} from '../../../../graphql/magento'
import { Maybe, Money, ProductLabelStatus } from '../../../../graphql/search'
import { useDispensaryContext } from '../../../../lib/dispensaries'
import { contentBlocks, displayAttribute } from '../../../../utils/itemTools'
import BasicAccordion from '../../../global/BasicAccordion'
import ItemControlsContainer from '../../../products/details/ItemControlsContainer'
import ItemHeading from '../../../products/details/ItemHeading'
import ProductDescription from '../../../sanity-page/ProductDescription/index'
import DataSheetDisclaimer from '../DataSheetDisclaimer'
import DataSheetLinks from '../DataSheetLinks'
import { ProtocolsWithProduct } from '../ProtocolsWithProduct'
import ItemAdvisorTags from './ItemAdvisorTags'
import ItemContentBlock from './ItemContentBlock'

export interface ItemDetailsProps {
  className?: string
  item: ConfigurableProductDetailsFragment
  onVariantSelected: (itemValue: string) => void
  sanityParentProduct?: SanityParentProductFragment | null
  sanityChildProduct?: NonNullable<SanityParentProductFragment['products']>[number] | null
  selectedVariant: SimpleProductDetailsFragment | null | undefined
  variantLabelStatus: ProductLabelStatus | undefined
  variantRetailPrice: Maybe<Money> | undefined
}

const StyledShortDescription = styled.p`
  && {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: 1rem;
    margin-bottom: 0.875rem;
  }
`

const ItemDetails: FC<ItemDetailsProps> = ({
  className,
  item,
  onVariantSelected,
  sanityParentProduct,
  sanityChildProduct,
  selectedVariant = null,
  variantLabelStatus,
  variantRetailPrice,
}) => {
  // Note: the short description is only available on the parent product
  const shortDescription = sanityParentProduct?.shortDescription ?? undefined
  const dispensary = useDispensaryContext()

  return (
    <div className={className}>
      <ItemHeading
        item={item}
        sanityParentProduct={sanityParentProduct}
        selectedVariant={selectedVariant}
        retailPrice={variantRetailPrice}
      />
      <div className="top-content">
        <ItemControlsContainer
          item={item}
          controls="all"
          sanityParentProduct={sanityParentProduct}
          onVariantSelected={onVariantSelected}
          selectedVariant={selectedVariant}
          variantLabelStatus={variantLabelStatus}
          analyticsEventAppContext={dispensary ? 'dispensary_pdp' : 'pdp'}
        />
        <div>
          {shortDescription && <StyledShortDescription>{shortDescription}</StyledShortDescription>}
          <ItemAdvisorTags item={item} selectedVariant={selectedVariant} />
          <DataSheetLinks
            item={item}
            selectedVariant={selectedVariant}
            sanityParentProduct={sanityParentProduct}
          />
          <DataSheetDisclaimer />
          <div>
            <BasicAccordion title="About This Product" defaultExpanded={true}>
              <ProductDescription
                item={item}
                selectedVariant={selectedVariant}
                sanityParentProduct={sanityParentProduct}
                sanityChildProduct={sanityChildProduct}
              />
            </BasicAccordion>
            <ProtocolsWithProduct selectedVariant={selectedVariant} />
          </div>
        </div>
      </div>
      {contentBlocks.map((content) => (
        <ItemContentBlock
          key={content}
          content={displayAttribute({ item, selectedVariant, attribute: content })}
        />
      ))}
    </div>
  )
}

const StyledItemDetails = styled(ItemDetails)({
  marginLeft: '15px',
  marginRight: '15px',
  maxWidth: '450px',
  width: '100%',
  '.top-content': {
    marginBottom: '20px',
  },
  '@media(min-width: 600px)': {
    padding: '50px 25px 25px',
    '.top-content': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
})

export default StyledItemDetails
